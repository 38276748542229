export const data = {
    experience: [
      {
        title: "Wordpress Team leader",
        subTitle: "Meteor BD Ltd. -Dhaka, BD (Main Canada)",
        description:
          "Various kind of wordPress sites for Clients from all over the world.",
          date: "2019 - Present",
      },
      {
        title: "Wordpress Developer",
        subTitle: "Wilylab Limited. -Dhaka, BD",
        description:
          "Developing unique WordPress themes themes for ThemeForest.",
          date: "2015 - 2018",
      },
      {
        title: "Front End Developer",
        subTitle: "Noksha BD Limited. -Dhaka, BD",
        description:
          "Clients spanning various industries, including finance, healthcare,  e-commerce.",
          date: "2014 - 2015",
      },
    ],
    education: [
      {
        title: "PHP/Wordpress",
        subTitle: "",
        description:
          "Best and Practical Envato standard Knowledge on Wordpress , PSD to Wordpress , Backend module, wordpress plugin, woocommerce. visual composer, Elementor, Divi, Redux, Customizer. API integration. My work has been recognized and praised by users and fellow developers alike",
      },
      {
        title: "React/Javascript",
        subTitle: "",
        description:
          "Developing dynamic and interactive front-end UI Component. Multi-page web applications, React developers often implement routing using libraries like React Router. Works on State Management, hooks, User events, Props, JSX, React DOM, Sass Styling, API integration",
      },
      {
        title: "HTML/CSS/jQuery",
        subTitle: "",
        description:
          "Best and Practical Envato standard  Knowledge on HTML5, new features like custom attribute , new attributes, custom attributes. Updated CSS Properties & selectors like:  CSS Grid Layout, pseudo-class selector, pseudo-element selector, flex box etc  ",
      },
    ],
  };