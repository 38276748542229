import React, { useState, useEffect } from 'react';
import Pageheader from '../../components/pageHeader';

import { BsBriefcase } from 'react-icons/bs';


import Image1 from "../../images/image1.jpg";
import Image2 from "../../images/image2.jpg";
import Image3 from "../../images/image3.jpg";
import Image4 from "../../images/image4.jpg";
import Image5 from "../../images/image5.jpg";
import Image6 from "../../images/image6.jpg";
import Image7 from "../../images/image7.jpg";
import Image8 from "../../images/image8.jpg";
import Image9 from "../../images/image9.jpg";
import Image10 from "../../images/image10.jpg";
import Image11 from "../../images/image11.jpg";
import Image12 from "../../images/image12.jpg";
import Image13 from "../../images/image13.jpg";
import Image14 from "../../images/image14.jpg";
import Image15 from "../../images/image15.jpg";
import Image16 from "../../images/image16.jpg";
import Image17 from "../../images/image17.jpg";
import Image18 from "../../images/image18.jpg";
import Image19 from "../../images/image19.jpg";
import Image20 from "../../images/image20.jpg";
import Image21 from "../../images/image21.jpg";
import Image22 from "../../images/image22.jpg";
import Image23 from "../../images/image23.jpg";
import Image24 from "../../images/image24.jpg";
import Image25 from "../../images/image25.jpg";
import "./styles.scss";


const portfolioData = [
    {
        id: 3,
        un: "1",
        name: "Real Estate",
        image: Image9,
        link: "https://www.radiestates.com",
    },
    {
        id: 2,
        un: "2",
        name: "WooCommerce",
        link: "https://www.theimporter.net.au",
        image: Image11,
    },
    {
        id: 2,
        un: "3",
        name: "Directory",
        image: Image1,
        link: "https://thelawq.com",
    },
    {
        id: 2,
        un: "4",
        name: "Car Clinic",
        image: Image8,
        link: "https://dubbocarclinic.com",
    },
    {
        id: 2,
        un: "5",
        name: "Injury Lawyers",
        image: Image16,
        link: "https://www.bpclaw.com.au",
    },
    {
        id: 3,
        un: "6",
        name: "Legal Group",
        image: Image17,
        link: "https://www.cmmquaylegal.com.au/",
    },
    {
        id: 2,
        un: "7",
        name: "Supershop",
        image: Image10,
        link: "https://dubbomeatcentre.com.au/",
    },
    {
        id: 2,
        un: "8",
        name: "Paramount Lawyers",
        image: Image23,
        link: "https://marocchi.law/",
    },
    {
        id: 2,
        un: "9",
        name: "Deepfake Tech",
        image: Image24,
        link: "https://mimepicai.com/demo/",
    },
    {
        id: 2,
        un: "10",
        name: "News Agency ",
        image: Image7,
        link: "https://nextra.com.au/",
    },
    {
        id: 3,
        un: "11",
        name: "Immigration Law",
        image: Image20,
        link: "https://ajurialawyers.com/",
    },
    {
        id: 3,
        un: "12",
        name: "Criminal Law",
        image: Image22,
        link: "https://www.northshorecriminallaw.com.au/",
    },
    {
        id: 2,
        un: "13",
        name: "Lawyers Group",
        image: Image12,
        link: "http://www.dangerfieldexley.com.au/",
    },
    {
        id: 2,
        un: "14",
        name: "Legal Services",
        image: Image15,
        link: "https://buckleylawyers.com.au/",
    },
    {
        id: 2,
        un: "15",
        name: "Lawyers Group",
        image: Image18,
        link: "https://www.gerardmaloufpartners.com.au/",
    },
    {
        id: 2,
        un: "16",
        name: "Venture Capital",
        image: Image25,
        link: "http://hcdinsights.com/",
    },
    {
        id: 2,
        un: "17",
        name: "Lawyers Group",
        image: Image13,
        link: "https://www.arlaw.com.au/",
    },
    {
        id: 3,
        un: "18",
        name: "Lawyer's Profile",
        image: Image2,
        link: "https://goodman2.newgen20.com/",
    },
    {
        id: 2,
        un: "19",
        name: "Law Group",
        image: Image21,
        link: "https://www.lawgroup.com.au/",
    },
    {
        id: 2,
        un: "20",
        name: "Lawyer's Profile",
        image: Image19,
        link: "https://commercial-barrister.com.au/",
    },
    {
        id: 2,
        un: "21",
        name: "Family Law",
        image: Image14,
        link: "https://www.resolveconflict.com.au/",
    },
    {
        id: 2,
        un: "22",
        name: "Religious",
        image: Image6,
        link: "https://christianresourcehub.com/",
    },
    {
        id: 3,
        un: "23",
        name: "Lawyers Group",
        image: Image4,
        link: "https://milawyer.newgen20.com/",
    },
    {
        id: 2,
        un: "24",
        name: "Venture Capital",
        image: Image5,
        link: "https://a2zventure.com/",
    },
    
];


const filterData = [
    {
        filterId: 1,
        label: "All",
    },
    {
        filterId: 2,
        label: "Developement",
    },
    {
        filterId: 3,
        label: "Design",
    },
];

const Portfolio = () => {

    const [filteredvalue, setFilteredValue] = useState(1);
    const [hoveredValue, setHoveredValue] = useState(null);

    function handleFilter(currentId) {
        setFilteredValue(currentId);
    }

    function handleHover(index) {
        setHoveredValue(index);
    }

    console.log("====================================");
    console.log(hoveredValue);
    console.log("====================================");

    const filteredItems =
        filteredvalue === 1
            ? portfolioData
            : portfolioData.filter((item) => item.id === filteredvalue);

    console.log(filteredItems);
    const [divHeight, setDivHeight] = useState(window.innerHeight - 167);

    useEffect(() => {
      // Function to update div height based on window height minus 150 pixels
      const updateDivHeight = () => {
        setDivHeight(window.innerHeight - 167);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateDivHeight);
  
      // Remove event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateDivHeight);
      };
    }, []); // Empty dependency array ensures this effect runs only once
  
    const divStyle = {
        minHeight: `${divHeight}px`,
    };
    return (
        <section id="portfolio" className="portfolio" style={divStyle}>
            <div>
                <Pageheader headerText='Portfolio' icon={<BsBriefcase size={40} />} />
            </div>
            <div className="portfolio__content">
                <ul className="portfolio__content__filter">
                    {filterData.map((item) => (
                        <li
                            className={item.filterId === filteredvalue ? "active" : ""}
                            onClick={() => handleFilter(item.filterId)}
                            key={item.filterId}
                        >
                            {item.label}
                        </li>
                    ))}
                </ul>
                <div className="portfolio__content__cards">
                    {filteredItems.map((item, index) => (
                        <div
                            className="portfolio__content__cards__item"
                            key={`cardItem${item.un.trim()}`}
                            onMouseEnter={() => handleHover(index)}
                            onMouseLeave={() => handleHover(null)}
                        >
                            <div className="portfolio__content__cards__item__img-wrapper">
                                <a>
                                    <img alt="dummy data" src={item.image} />
                                </a>
                            </div>
                            <div className="overlay">
                                {index === hoveredValue && (
                                    <div>
                                        <p>{item.name}</p>
                                        <br/><br/>
                                        <a href={item.link} target='_blank' className='button'>Visit</a>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default Portfolio;