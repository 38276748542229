
import './App.scss';
import {Routes, Route} from 'react-router-dom';
import Home from './containers/home';
import About from './containers/about';
import Resume from './containers/resume';
import Skills from './containers/skills';
import Portfolio from './containers/portfolio';
import Contact from './containers/contact';
import Navbar from './components/navBar';
import Footerbuild from './components/footer';
import React, { useState, useEffect, CSSProperties } from 'react';
import {RingLoader} from 'react-spinners';

const override: CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
};
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //const timer = setTimeout(() => {
      setLoading(false); // After 1 second, set loading to false
    //}, 500); // Delay for 1 second (1000 milliseconds)

    // Cleanup the timer to avoid memory leaks if the component unmounts
    //return () => clearTimeout(timer);
  }, []);
  return (
    
    <div className="App">
  {loading ? (
        <RingLoader loading={true} color="#e74c3c" cssOverride={override} />
      ) : (
        <div>
      <Navbar/>
   
    <div className='App_main_page_contents'>
    <Routes>
        <Route index path='/' element={<Home/>}/>
        <Route  path='/experiences' element={<About/>}/>
        <Route  path='/resume' element={<Resume/>}/>
        <Route  path='/skills' element={<Skills/>}/>
        <Route  path='/portfolio' element={<Portfolio/>}/>
        <Route  path='/contact' element={<Contact/>}/>
  
      </Routes>
    </div>
    <Footerbuild/>
    </div>
    )}
    </div>
  );
}

export default App;
