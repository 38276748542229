import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Pageheader from '../../components/pageHeader';
import './styles.scss';
import { BsChatRightText,BsSend } from 'react-icons/bs';
const Contact = () => {
    const [divHeight, setDivHeight] = useState(window.innerHeight - 251);

    useEffect(() => {
      // Function to update div height based on window height minus 150 pixels
      const updateDivHeight = () => {
        setDivHeight(window.innerHeight - 251);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateDivHeight);
  
      // Remove event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateDivHeight);
      };
    }, []); // Empty dependency array ensures this effect runs only once
  
    const divStyle = {
      height: `${divHeight}px`,
    };
//end height

    const form = useRef();
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_aw2pf8i', 'template_j9ey6nt', form.current, 'WI_4HBbjg4-UhZr8c')
        .then(
          (result) => {
            console.log(result.text);
          
            setIsSubmitted(true); // Set the state to indicate the form has been submitted
          },
          (error) => {
            console.log(error.text);
          }
        );
    };
  
    return (
      <div>
        <Pageheader headerText='Contact' icon={<BsChatRightText size={40} />} />
        <div className='contactform'style={divStyle}>
        {!isSubmitted ? ( // Conditionally render the form based on isSubmitted state
        
          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" rows="4" />
     
           <div className='send'> <input  type="submit" value="Send" /><span><BsSend size={20}/></span></div>
          </form>
        
        ) : (
          <p>Thank you for your submission!</p>
        )}
        </div>
      </div>
    );
  };
  
  export default Contact;