import './styles.scss';

const Pageheader = (props)=> {
    const { headerText, icon} = props;

    return (

        <div className="pageheader">
            <h2>{headerText}</h2>
            <span>{icon}</span>
        </div>


    );
};

export default Pageheader;