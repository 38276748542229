import React,{useState} from 'react';
import { FaBars, FaReact } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {HiX} from 'react-icons/hi';
import './styles.scss';

const data = [
    {
        label: 'Home',
        to: '/'
    },
    {
        label: 'Experiences',
        to: '/experiences'
    },

    {
        label: 'Portfolio',
        to: '/portfolio'
    },
    {
        label: 'Contact',
        to: '/contact'
    }
]


const Navbar = () => {

    

    const [activeLink, setActiveLink] = useState(null); // State to track the active link
    const [toggleIcon, setToggleIcon] = useState(false)
    const handleTggleIcon = () => {
        setToggleIcon(!toggleIcon);
    }
    const handleLinkClick = (label) => {
        setActiveLink(label); // Update the active link when a link is clicked
    }
    return (
        <div>
            <nav className="navbar">
                <div className="navbar_container">
                    <Link to={'/'} className='navbar_container_logo'>
                        <FaReact size={30} />
                    </Link> <span>SM. Mahmodul Hassan</span>
                </div>
                <ul className={`navbar_container_menu ${toggleIcon ? 'active' : ''}`}>

                    {
                        data.map((item, key) => (
                            <li id={item.label} key={key} className={`navbar_container_menu_item ${activeLink === item.label ? 'active' : ''}`}>
                                <Link
                                to={item.to}
                                className='navbar_item_link'
                                onClick={() => handleLinkClick(item.label)} // Call the handler on link click
                            >
                                    {item.label}
                                </Link>
                            </li>
                        ))
                    }

                </ul>
                <div className='nav-icon' onClick={handleTggleIcon}>
                    {
                        toggleIcon ? <HiX size={30}/> : <FaBars size={30}/>
                    }
                </div>
            </nav>
        </div>
    )
    
}

export default Navbar;