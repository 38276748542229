import React from 'react';
import Pageheader from '../../components/pageHeader';
import './styles.scss';
import {BsCodeSlash} from 'react-icons/bs';
const Skills=()=>{
    return(
        <div>
            <Pageheader headerText='Skills' icon ={<BsCodeSlash size={40}/>} />
        </div>
    )
}
export default Skills;