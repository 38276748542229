import React, { useState, useEffect } from 'react';
import Pageheader from '../../components/pageHeader';
import { BsPersonVcard } from 'react-icons/bs';
import { BsInfoCircleFill } from "react-icons/bs";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css'
import { data } from "./utils";
import './styles.scss';
import { MdWork } from 'react-icons/md';

const Resume = () => {

    const [divHeight, setDivHeight] = useState(window.innerHeight - 299);

    useEffect(() => {
      // Function to update div height based on window height minus 150 pixels
      const updateDivHeight = () => {
        setDivHeight(window.innerHeight - 299);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateDivHeight);
  
      // Remove event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateDivHeight);
      };
    }, []); // Empty dependency array ensures this effect runs only once
  
    const divStyle = {
      height: `${divHeight}px`,
    };

    return (
        <section className='sectionmain'>
            {/* <Pageheader headerText='Resume' icon={<BsPersonVcard size={40} />} /> */}
            <div className="timeline">
                <div className="timeline__left">
                    <h3 className="timeline__left_header">Offices</h3>
                    <VerticalTimeline
                        layout={"1-column"}
                        lineColor="var(--red-theme-main-color)"
                    >
                        {data.experience.map((item, i) => (
                            <VerticalTimelineElement
                                key={i}
                                className="timeline__experience__vertical-timeline-element"
                                contentStyle={{
                                    background: 'none',
                                    color: 'var(--yellow-theme-sub-text-color)',
                                    border: '1.5px solid var(--red-theme-main-color)'
                                }}
                                date={item.date}
                                icon={<MdWork />}
                                iconStyle={{
                                    background: '#fff',
                                    color: 'var(--red-theme-main-color)',

                                }}
                            >
                                <div className="vertical-timeline-element-title-wrapper">
                                    <h3>
                                        {item.title}
                                    </h3>

                                    <h4>
                                        {item.subTitle}
                                    </h4>
                                </div>
                                <p className="vertical-timeline-element-title-wrapper-description">{item.description} </p>

                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </div>
                <div className="timeline__right">
                    <h3 className="timeline__right_header">Mejor Tools</h3>
                    <VerticalTimeline
                        layout={"1-column"}
                        lineColor="var(--red-theme-main-color)"
                    >
                        {data.education.map((item, i) => (
                            <VerticalTimelineElement
                                key={i}
                                className="timeline__experience__vertical-timeline-element"
                                contentStyle={{
                                    background: 'none',
                                    color: 'var(--yellow-theme-sub-text-color)',
                                    border: '1.5px solid var(--red-theme-main-color)'
                                }}
                              
                                icon={<MdWork />}
                                iconStyle={{
                                    background: '#fff',
                                    color: 'var(--red-theme-main-color)',

                                }}
                            >
                                <div className="vertical-timeline-element-title-wrapper">
                                    <h3>
                                        {item.title}
                                    </h3>

                                    <h4>
                                        {item.subTitle}
                                    </h4>
                                </div>
                                <p className="vertical-timeline-element-title-wrapper-description">{item.description} </p>

                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </div>

            </div>
        </section>
    )
}
export default Resume;