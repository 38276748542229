import React, { useState, useEffect } from 'react';
import Pageheader from '../../components/pageHeader';
import './styles.scss';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { DiWordpress, DiPhp, DiJavascript1, DiReact, DiAndroid } from 'react-icons/di';
import { Animate } from "react-simple-animate";
import Resume from '../../containers/resume';
const personalDetails = [
    {
        label: "Name",
        value: "SM. Mahmodul Hassan",
    },
 
    {
        label: "Address",
        value: "Mirpur-2, Dhaka, Bangladesh",
    },
    {
        label: "Email",
        value: "mishuace360bd@gmail.com",
    },
    {
        label: "Contact No",
        value: "+8801915764738",
    },
];

const jobSummary =
    "Hello, I'm Mahmodul, a passionate WordPress developer and React front-end developer, and I'm here to help you transform your digital dreams into reality. With years of experience in web development, I have the skills and expertise to create stunning, high-performance websites that not only look great but also drive results.";

const About = () => {

    const [divHeight, setDivHeight] = useState(window.innerHeight - 249);

    useEffect(() => {
      // Function to update div height based on window height minus 150 pixels
      const updateDivHeight = () => {
        setDivHeight(window.innerHeight - 249);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateDivHeight);
  
      // Remove event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateDivHeight);
      };
    }, []); // Empty dependency array ensures this effect runs only once
  
    const divStyle = {
      height: `${divHeight}px`,
    };


    return (
        <section className='sectionmain'>
            <Pageheader headerText='Experiences' icon={<BsFillPersonCheckFill size={40} />} />
            <Resume/>
            <div className='innercontents'>
               
                <div className='innercontents_left'>
                    <Animate
                        play
                        duration={0.5}
                        delay={0.1}
                        start={{
                            transform: "translateX(-900px)",
                        }}
                        end={{
                            transform: "translatex(0px)",
                        }}
                    >
                        <h3>Career Objective</h3>
                        <p>{jobSummary}</p>
                    </Animate>
                    <Animate
                        play
                        duration={0.5}
                        delay={0.1}
                        start={{
                            transform: "translateX(500px)",
                        }}
                        end={{
                            transform: "translatex(0px)",
                        }}
                    >
                        <h3 className="personalInformationHeaderText">
                            Personal Information
                        </h3>
                        <ul>
                            {personalDetails.map((item, i) => (
                                <li key={i}>
                                    <span className="title">{item.label}</span>
                                    <span className="value">{item.value}</span>
                                </li>
                            ))}
                        </ul>
                    </Animate>
                </div>
                <div className='innercontents_right'>
                    <Animate
                        play
                        duration={0.5}
                        delay={0.1}
                        start={{
                            transform: "translateX(600px)",
                        }}
                        end={{
                            transform: "translatex(0px)",
                        }}
                    >
                        <div className='innercontents_right_inner'>
                            <div>
                                <DiWordpress size={50} color='#E74C3C' />
                            </div>
                            <div>
                                <DiPhp size={50} color='#E74C3C' />
                            </div>
                            <div>
                                <DiJavascript1 size={50} color='#E74C3C' />
                            </div>
                            <div>
                                <DiReact size={50} color='#E74C3C' />
                            </div>
                            <div>
                                <DiAndroid size={50} color='#E74C3C' />
                            </div>
                        </div>
                    </Animate>
                </div>
            </div>
        </section>
    )
}
export default About;