import React, { useState, useEffect } from 'react';
import mishu from "../../mishu.mp4";
import Thumbnail from "../../thumbnail.jpg";
import { useCallback } from "react";
import Particles from "react-tsparticles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import partclesfile from "./partclesfile";
import TypeWriter from "./typewriter";
import { useNavigate } from 'react-router-dom';
import { Animate } from 'react-simple-animate';
import './styles.scss';
const Home = () => {

    const [divHeight, setDivHeight] = useState(window.innerHeight - 167);

    useEffect(() => {
      // Function to update div height based on window height minus 150 pixels
      const updateDivHeight = () => {
        setDivHeight(window.innerHeight - 167);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateDivHeight);
  
      // Remove event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateDivHeight);
      };
    }, []); // Empty dependency array ensures this effect runs only once
  
    const divStyle = {
      height: `${divHeight}px`,
    };
//end height

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/contact');
    }
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
    return (
        <div className='homewraper'>
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={partclesfile}
            />
            <div className="homemain" style={divStyle}>

                <div className='contents'>
                    <Animate play duration={1} delay={0.5} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                        <h1>Hello, I'm Mahmodul</h1>
                    </Animate>
                    <Animate play duration={.8}
                        delay={1.3} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                       <TypeWriter />
                    </Animate>
                    
                    <Animate play duration={1}
                        delay={0.5} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                        <p>
                            Over the past 7 years of my professional journey, I have honed my skills in PHP, JavaScript and its libraries, Module building and technologies relevant to the position, and have gained a comprehensive understanding of software development methodologies and best practices.
                        </p>
                    </Animate>

                    <Animate play duration={.8}
                        delay={1.3} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                        <button onClick={handleNavigate}>Contact</button> 
                    </Animate>
                    

                </div>
                <div className='player-wrapper'>
                <Animate play duration={.5} delay={.5} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                <video width="100%" loop controls={true} poster={Thumbnail}>
                        <source src={mishu} type="video/mp4" />

                    </video>
                    </Animate>
                    
                </div>

            </div>
        </div>
    )
}
export default Home;