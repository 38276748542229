import './styles.scss';

function Footerbuild (){

    return(
        <footer className='footer'>

            <div className="footerbottom">
            ©copyright mahmodul.
            </div>
        </footer>
    )
}

export default Footerbuild;